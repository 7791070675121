@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif "Myriad Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clip-text {
  font-size: 55px;
  font-weight: 900;
  line-height: 1;
  position: relative;
  display: inline-block;
  /* margin: 0.25em; */
  padding: 0.9em 0.75em;
  text-align: center;
  /* Color fallback */
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}

.clip-text:before,
.clip-text:after {
  position: absolute;
  content: "";
}

/* Background */
.clip-text:before {
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: inherit;
}

/* Text Background (black zone) */
.clip-text:after {
  position: absolute;
  z-index: -1;
  top: 0.125em;
  right: 0.125em;
  bottom: 0.125em;
  left: 0.125em;
  background-color: #000;
}

.clip-text_one {
  font-family: "Rubik", sans-serif;

  background-image: linear-gradient(
      135deg,
      transparent 0%,
      transparent 17%,
      rgba(87, 146, 234, 0.6) 17%,
      rgba(87, 146, 234, 0.6) 59%,
      transparent 59%,
      transparent 64%,
      rgba(34, 81, 222, 0.6) 64%,
      rgba(34, 81, 222, 0.6) 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 2%,
      rgb(87, 146, 234) 2%,
      rgb(87, 146, 234) 46%,
      rgb(114, 178, 239) 46%,
      rgb(114, 178, 239) 54%,
      transparent 54%,
      transparent 63%,
      rgb(7, 48, 216) 63%,
      rgb(7, 48, 216) 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.viewer-layout-toolbar {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  color: #333333;
}
